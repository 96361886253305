import React from "react"
import moment from "moment"
import { Layout, Parallax, NewsletterSection } from "@components/layout"
import { PostItem, CommentSection } from "@components/blog"
import { withHtml } from "@components/logic"
import { Tags, DirectionArrows } from "@components/theme"
import { Breadcrumbs, AnimatedSection } from "@components/shared"
import icon from "./img/time-is-money.png"
// import icon from "./img/business.png"

import {
  news,
  post__wrapper,
  post__header,
  post__content,
  icon__wrap,
  content__wrapp,
} from "./styles/post.module.scss"

const reduceNodes = (withNodes) => {
  const { nodes } = withNodes
  return nodes.reduce((acc, curr) => {
    if (acc.length !== 0) {
      return `${acc}, ${curr.name}`
    }
    return curr.name
  }, "")
}

const Title = withHtml("h1")
const Content = withHtml("article")
const linkModifier = ({ slug }) => `/blog/${slug}/`

const Post = ({ pageContext }) => {
  const { page, nextPost, prevPost } = pageContext
  const { categories, tags } = page
  const [mainCategory] = categories.nodes

  return (
    <Layout
      seo={pageContext.page.seo}
      siteMetaData={pageContext.page.siteMetaData}
    >
      <div className={post__wrapper}>
        <Breadcrumbs
          elements={[
            {
              label: "Blog",
              url: `/blog/`,
            },
            {
              label: mainCategory.name,
              url: `/blog/categories/${mainCategory.slug}`,
            },
            {
              label: page.title,
              url: `/blog/${page.slug}/`,
            },
          ]}
        />

        <Title className={post__header}>{page.title}</Title>
        {/* <h5>Data: {moment(page.date).format("DD / MM / YYYY")}</h5> */}

        <AnimatedSection></AnimatedSection>

        <Tags tags={tags.nodes} tagsUrl={"/tags"} />

        <div className={content__wrapp}>
          <img src={icon} alt="" />
          <Content className={post__content}>{page.content}</Content>
        </div>
        {/* <NewsletterSection  className={news}/> */}

        <DirectionArrows
          prevItem={prevPost}
          nextItem={nextPost}
          linkModifier={linkModifier}
        />
      </div>
    </Layout>
  )
}

export default Post
